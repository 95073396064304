.mainDiv {
  background: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(17px);
  backdrop-filter: blur(17px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  z-index: 1000;
}
.container {
  padding: 30px 16px;
  background: rgba(277,277,277);
  color: black;
  backdrop-filter: blur(60px);
  border-radius: 12px;
  width: 100%;
  max-width: 350px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.button {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 10px 45px;
  width: 50%;
  margin: 0 auto;
  color: black;
  background: #a2c4cd;
}
