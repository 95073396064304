.banner {
  padding: 30px 5%;
}
.banner h2 {
  color: #161616;
  font-family: "Satoshi-Variable";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 28.6px */
  margin-bottom: 15px;
}
.banner p {
  color: #303030;
  font-family: "Satoshi-Variable";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  max-width: 80%;
}
