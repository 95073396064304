.reactPlayer {
  overflow: hidden;
  object-fit: contain;
}

.tagLine {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  opacity: 75%;
}
.button {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  letter-spacing: -0.32px;
  text-transform: uppercase;
}
