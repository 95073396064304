.container {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
}
.parentDiv {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background: rgba(277, 277, 277, 0.1);
  -webkit-backdrop-filter: blur(17px);
  backdrop-filter: blur(17px);
  display: flex;
  flex-direction: column;
  padding: 20px;
  transition: all 0.2s ease-in;
  opacity: 0;
}
.enter {
  opacity: 1;
}
.button {
  border-radius: 12px;
  background: #262626;
  width: fit-content;
  min-width: 100px;
  box-shadow: 3px 3px 1px 0px rgba(0, 0, 0, 0.25);
  padding: 12px;
  white-space: nowrap;
  transition: all 0.3 ease-in-out;
}
.reportButton{
  border-radius: 12px;
  background: #262626;
  width: fit-content;
  min-width: 100px;
  box-shadow: 3px 3px 1px 0px rgba(0, 0, 0, 0.25);
  padding:7px 12px;
  white-space: nowrap;
  transition: all 0.3 ease-in-out;
}
.reportButton:disabled {
  opacity: 50%;
  cursor: not-allowed;
}
/* talewind md device */
@media (max-width: 768px) {
  .parentDiv {
    height: fit-content;
    padding: 20px 0;
    background: none;
  }
  .container {
    padding: 30px 0;
  }
  .button {
    padding: 7px 12px;
  }
}
