.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  padding: 0 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.caroselContainer {
  width: 100%;
  height: 100%;
  max-width: 1440px;
}
/* talewind md device */
@media (max-width: 768px) {
  .container {
    padding: 0 20px;
  }
}
