.popupBackground {
  background: rgba(18, 18, 18, 0.44);
  display: flex;
  align-items: center;
  justify-content: center;
}

.parentDiv {
  border-radius: 18px;
  background: #fff;
  width: 100%;
  max-width: 576px;
  height: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.parentDiv h1 {
  color: #282828;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
}
.parentDiv p {
  color: #5f6980;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
.parentDiv button {
  border-radius: 30px;
  border: 2px solid #5f6980;
  color: #5f6980;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.2px;
  width: 100%;
  padding: 12px 20px;
}
.banner {
  background: linear-gradient(0deg, #ffed4c 0%, #ffed4c 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
}
