.mainDiv {
  background: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(17px);
  backdrop-filter: blur(17px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  z-index: 1000;
}
.container {
  padding: 30px 16px;
  background: rgba(255, 255, 255, 0.969);
  backdrop-filter: blur(60px);
  color: #000;
  border-radius: 12px;
  width: 100%;
  max-width: 350px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.button {
  border-radius: 8px;
  background: #2c3892;
  box-shadow: 0px 0px 14px 0px rgba(209, 54, 182, 0.3);
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.32px;
  width: 100%;
  padding: 12px 16px;
  max-width: 300px;
}
.cancel {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 14px 0px rgba(209, 54, 182, 0.3);
  color: #2c3892;
  border: 1px solid #2c3892;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.32px;
  width: 100%;
  padding: 12px 16px;
  max-width: 300px;
}
