.container {
  padding: 30px 16px;
  background: rgba(37, 37, 37, 0.6);
  backdrop-filter: blur(60px);
  border-radius: 12px;
  height: fit-content;
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  gap: 15px;
}
.container img {
  width: 150px;
  object-fit: cover;
  aspect-ratio: 1;
}
.container button {
  padding: 10px 20px;
  background: black;
  color: white;
  border-radius: 12px;
}

@media (max-width: 768px) {
  .container {
    padding: 16px;
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }
  .container img {
    width: 75px;
  }
  .container button {
    width: 100%;
  }
}
