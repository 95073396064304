.wrapper {
    max-width: 600px;
    padding: 0px 60px;
    margin: 50px auto;
    display: flex;
    flex-wrap: wrap;
    gap: 2em;
}

.wrapper img {
    cursor: pointer;
}

/* modal */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.overlay .file {
    display: block;
    max-width: 100%;
    max-height: 80%;

    margin-bottom: 0;
    box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);

}

.overlay>span {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px;
    color: #ffffff;
    z-index: 999;
    cursor: pointer;
}

.overlay-arrows_left {
    display: flex;
    border-radius: 50px;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    width: 50px;
    height: 50px;
    left: 0;
    z-index: 999;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}

.overlay-arrows_left svg {
    width: 50px;
    height: 50px;
}

.overlay-arrows_right svg {
    width: 50px;
    height: 50px;
}

.overlay-arrows_right {
    display: flex;
    border-radius: 50px;
    color: white;
    justify-content: space-between;
    position: absolute;
    right: 0;
    width: 50px;
    height: 50px;
    top: 50%;
    z-index: 999;
}

.participentNames {
    padding-left: 18px;
    width: 219.77px;
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;

    color: #eaeaea;
    display: flex;
    align-items: center;
    letter-spacing: -0.005em;
}

.videovww {
    position: absolute;
    width: 90px;
    height: 18px;
    left: 80px;
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */
    display: flex;
    align-items: center;
    letter-spacing: -0.005em;
    color: #787878;
}

#copy {
    position: relative;
    box-sizing: border-box;
    background-color: #482ff7;
    /* color: $white; */
    width: 180px;
    min-height: 44px;
    font-size: 1rem;
    /* font-family: $btn-font; */
    font-weight: 500;
    text-transform: uppercase;
    padding: 5px;
    border: 0;
    /* border-radius: $radius; */
    outline: none;
    cursor: pointer;
    user-select: none;
    /* box-shadow: $shadow; */
}

#copy::before {
    content: "";
    width: 16px;
    height: 16px;
    bottom: -20px;
    left: 82px;
    clip-path: polygon(50% 40%, 0% 100%, 100% 100%);
}

#copy::after {
    content: attr(tooltip);
    width: 140px;
    bottom: -48px;
    left: 20px;
    padding: 5px;
    border-radius: 4px;
    font-size: 0.8rem;
}

#copy::before,
#copy::after {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    box-sizing: border-box;
    /* background-color: $black; */
    /* color: $white; */
    transform: translateY(-10px);
    transition: all 300ms ease;
}

#copy::hover:before,
#copy::hover:after {
    opacity: 1;
    transform: translateY(0);
}
