.useCases h2 {
  color: #2c3892;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.useCases pre {
  color: #111;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 25.2px */
  opacity: 0.6;
}
.useCases h3 {
  color: #111;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 33.6px */
}
.step {
  border-radius: 10px;
  background: #8f90a6;
  padding: 8px 16px;
  width: fit-content;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.graphics {
  border-radius: 20px;
  background: #d9d9d9;
  width: 100%;
  aspect-ratio: 1 / 1;
}
.graphics img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  object-fit: contain;
}
