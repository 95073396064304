@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,500;0,600;1,400&display=swap");

.mainDiv {
  background: #171717;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  z-index: 1000;
}
.container {
  padding: 30px 100px;
  width: 100%;
  max-width: 1440px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.mainDiv h4 {
  color: #fff;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
}
.mainDiv p {
  color: #fff;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
/* talewind sm	 decvice */
@media (max-width: 640px) {
  .container {
    padding: 30px 16px;
    text-align: center;
  }
  .mainDiv h4 {
    font-size: 22px;
  }
  .mainDiv p {
    font-size: 16px;
    font-weight: 400;
  }
}
