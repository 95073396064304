.mainDiv {
  background: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(17px);
  backdrop-filter: blur(17px);
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  padding: 40px 20px;
  text-align: center;
  z-index: 1;
}
.container {
  background: rgba(255, 255, 255, 1);
  /* backdrop-filter: blur(60px); */
  border-radius: 12px;
  height: fit-content;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  color: #000;
  overflow: hidden;
}
.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  gap: 10px;
  overflow: hidden;
}
.header h2 {
  color: #2c3892;
  font-family: "Spectral", serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 29.4px */
  overflow-wrap: break-word;
  min-width: 0;
  text-align: left;
}
.header button {
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  padding: 10px;
}
.button {
  border-radius: 8px;
  background: #2c3892;
  box-shadow: 0px 0px 14px 0px rgba(209, 54, 182, 0.3);
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.32px;
  width: fit-content;
  padding: 12px 16px;
}
.table {
  width: 70%;
  table-layout: auto;
  margin: auto;
}
.table tbody,
.table tr {
  width: 100%;
}
.table td:first-child {
  text-align: left;
}
.table td:last-child {
  text-align: right;
}
.table td {
  padding: 10px 5px;
}

@media (max-width: 640px) {
  .table {
    width: 100%;
  }
}
