.mainDiv {
  background: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(17px);
  backdrop-filter: blur(17px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  z-index: 1000;
}
.container {
  background: rgba(255, 255, 255, 0.969);
  backdrop-filter: blur(60px);
  color: #000;
  border-radius: 12px;
  width: 100%;
  max-width: 350px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  gap: 10px;
}
.header h2 {
  color: #2c3892;
  font-family: "Spectral", serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 29.4px */
  overflow-wrap: break-word;
  min-width: 0;
  text-align: left;
}
.header button {
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  padding: 10px;
}
.button {
  border-radius: 8px;
  background: #2c3892;
  box-shadow: 0px 0px 14px 0px rgba(209, 54, 182, 0.3);
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.32px;
  width: 100%;
  padding: 12px 16px;
  /* max-width: 300px; */
}

.phoneInput {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  outline: 2px solid transparent;
  outline-offset: 2px;
  border-width: 2px;
  border-radius: 0.375rem;
  background: #fff;
}
.phoneInput input {
  /* border: none;
  margin: 0;
  padding-right: 0;
  font-size: 16px;
  width: 100%;
  border-radius: 0 8px 8px 0; */
  font-size: 1rem;
}

.phoneInput input:first-child {
  width: 20%;
  border-radius: 0;
  padding-left: 12px;
  border-right: 1px solid #818181;
}
.error {
  margin-top: 7px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  text-align: left;
}
