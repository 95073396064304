.details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}
.container h1 {
  font-family: var(--secondary-font);
  font-size: 3.25rem;
  color: #fff;
  font-style: normal;
  font-weight: 400;
  line-height: 3.25rem;
}

.container p {
  font-family: var(--primary-font);
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.details img {
  /* border-radius: 120px; */
  /* border: 4px solid #373737; */
  width: 120px;
  height: 120px;
  object-fit: contain;
}

.details button {
  padding: 14px 32px;
  background: #fff;
  color: #000;
  border-radius: 30px;
  font-family: var(--primary-font);
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-auto-rows: 300px;
  margin-top: 64px;
}
.gridItems4:nth-child(1) {
  /* grid-row: span 2; */
}

.more {
  display: flex;
  gap: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  text-align: right;
}
.more button {
  background: #cf0;
  color: #000;
  filter: drop-shadow(rgba(204, 255, 0, 0.6) 4px 4px 0px);
  border-radius: 5px;
  width: fit-content;
  padding: 12px 43px;
  font-family: var(--primary-font);
  font-size: 1.3125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  text-transform: uppercase;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .gallery {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media screen and (max-width: 768px) {
  .details {
    padding: 0 20px;
  }
  .details img {
    /* border-radius: 40px; */
    width: 40px;
    height: 40px;
  }
  .container h1 {
    font-size: 1.5rem;
    line-height: 2.375rem;
  }
  .container p {
    font-size: 0.875rem;
    line-height: 140%;
  }
  .details button {
    padding: 9px 16px;
    font-size: 0.8125rem;
  }
  .gallery {
    margin-top: 32px;
    /* grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-auto-rows: 194px; */
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-auto-rows: 388px;
  }
  .gridItems4:nth-child(1) {
    grid-row: span 1;
  }
  .gridItems4:last-child {
    /* grid-row: span 2;
    grid-column: span 2; */
    grid-row: span 1;
    grid-column: span 1;
  }
  .more {
    padding: 0 20px;
  }
  .span2 {
    grid-column: span 2;
  }
  .span1 {
    grid-column: span 1;
  }
  .more button {
    padding: 10px 17px;
    font-size: 0.875rem;
  }
  .more h1 {
    line-height: 2rem;
  }
}
