.priceWrapper {
  color: #000;
}
.priceWrapper .packDescription {
  color: #777;
  font-size: 12px;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.priceWrapper .packFeatures {
  font-size: 12px;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.priceWrapper h4 {
  font-size: 21px;
  font-family: var(--font-secondary);
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.42px;
  color: #000;
}
.priceWrapper p {
  font-size: 15px;
  font-family: var(--font-primary);
  font-weight: 600;
  line-height: 20px;
}
.priceWrapper li {
  list-style: circle;
  margin: 0 16px;
}
.button {
  padding: 10px 16px;
  border-radius: 54px;
  border: 1px solid #4e04c6;
  background: #4e04c6;
  color: #fff;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.24);
  white-space: nowrap;
}

@media (max-width: 768px) {
  .priceWrapper h4 {
    font-size: 21px;
    line-height: 20px;
    letter-spacing: 0.36px;
  }
  .priceWrapper p {
    font-size: 14px;
    font-weight: 500;
  }
}
