.title {
  max-width: 358px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  margin: 0 auto;
  text-align: center;
  background: linear-gradient(90.86deg, #ff377f 49.26%, #6726d1 85.28%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.viewGoviral {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 13px 14px;
  gap: 8px;
  width: max-content;
  margin: 21px auto 0 auto;
  height: 44px;
  background: #090909;
  border: 1px solid #bb134f;
  border-radius: 30px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.mail {
  font-family: "Inter", sans-serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  display: flex;
  align-items: center;
  gap: 7px;
}

.mailDesciption {
  color: #dfdfdf;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 30.8px */
  letter-spacing: 0.66px;
  text-transform: capitalize;
}
/* talewind md device */
@media (max-width: 768px) {
  .mail {
    font-size: 14px;
  }
  .mailDesciption {
    font-size: 15px;
  }
}
@media only screen and (min-width: 800px) {
  .title {
    max-width: 458px;
  }
}
