.mainDiv {
  background: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(17px);
  backdrop-filter: blur(17px);
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  padding: 40px 20px;
  text-align: center;
  z-index: 1;
}
.container {
  padding: 30px 16px;
  background: rgba(37, 37, 37, 0.6);
  backdrop-filter: blur(60px);
  border-radius: 12px;
  height: fit-content;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
.button {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 10px;
  width: 50%;
  margin: 0 auto;
  color: black;
  background: #a2c4cd;
}
.table {
  width: 70%;
  table-layout: auto;
  margin-bottom: 30px;
}
.table td:first-child {
  text-align: left;
}
.table td:last-child {
  text-align: right;
}
.table td {
  padding: 10px 5px;
}

@media (max-width: 640px) {
  .table {
    width: 100%;
  }
}
