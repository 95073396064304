.header {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
}
.landing {
  text-align: left;
  min-height: 70vh;
  height: 100%;
  background-position: right bottom;
  background-repeat: no-repeat;
  color: #111;
  margin-bottom: 80px;
  padding: 0 20px;
}
.landing h1 {
  color: #111;
  font-family: Inter;
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.landing p {
  color: #585981;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
}
.footer p {
  color: #585981;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
}
.footer button {
  color: #111;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 52px; /* 288.889% */
  display: block;
  padding: 5px 10px;
}
.footer h3 {
  color: #111;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px; /* 180% */
  margin-bottom: 12px;
}
.footer .copyright {
  color: #8f90a6;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 30px;
}
@media (max-width: 768px) {
  .landing {
    margin-bottom: 46px;
  }
  .landing h1 {
    font-size: 27px;
  }
}
