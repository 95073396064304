.signUpParent {
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(50px);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  padding: 50px 16px 50px 16px;
  overflow-y: auto;
}
.signupPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  backdrop-filter: blur(60px);
  border-radius: 12px;
  color: #000000;
  text-align: center;
  max-width: 520px;
  width: 100%;
  height: fit-content;
  overflow: hidden;
}
.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 0 20px;
}
.mobileLogin {
  /* padding: 0 20px; */
}
.mobileLogin input {
  width: 100%;
  height: 52px;
  background: #eeeeee;
  border-radius: 8px;
  border: none;
  padding: 13px;
}
.mobileLogin input[type="checkbox"] {
  width: 24px;
  height: 24px;
  accent-color: #00a989;

  color: #ffffff;
}
.whatsappLogin input {
  width: 100%;
  height: 52px;
  background: #eeeeee;
  border-radius: 8px;
  border: none;
  padding: 13px;
}
.phoneInput {
  display: flex;
  align-items: center;
  position: relative;
}

.country {
  border-right: 1px solid #c1c0c0;
  border-radius: 8px 0 0 8px;
  width: 30%;
  height: 52px;
  background: #eeeeee;
  padding: 13px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.phoneInput input {
  border: none;
  margin: 0;
  padding-right: 0;
  font-size: 16px;
  width: 100%;
  border-radius: 0 8px 8px 0;
}

.phoneInput input:first-child {
  width: 20%;
  border-radius: 0;
  padding-left: 5px;
}

.button {
  width: 50%;
  padding: 16px 0;
  border-radius: 8px;
  color: #ffffff;
}
.button[disabled] {
  opacity: 60%;
  cursor: not-allowed;
}
.dropdownList {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  filter: drop-shadow(rgba(0, 0, 0, 0.4) 2px 2px 2px);
  border-radius: 0 0 8px 8px;
  max-height: 150px; /* Adjust the max-height as needed */
  overflow-y: auto;
  text-align: left;
  z-index: 10;
}

/* Style each dropdown item */
.dropdownList > div {
  padding: 10px;
  cursor: pointer;
}

/* Highlight the selected item */
.dropdownList > div:hover {
  background-color: #f0f0f0;
}
