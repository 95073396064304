/* Pulsating */

.pulsating-circle2 {
  /* position: absolute; 
    right: 30%;
    top: 60%;*/
  height: 50px;
  width: 49.72066879272461px;
  /* position: absolute; */
  /* right: 16px;
    top: 30px; */
  transform: translateX(-50%) translateY(-50%);
  width: 30px;
  height: 30px;
}
.bgMe {
  background-color: var(--secondary-color);
}
.pulsating-circle2::before {
  content: "";
  position: relative;
  display: block;
  width: 300%;
  height: 300%;
  box-sizing: border-box;
  margin-left: -100%;
  margin-top: -100%;
  left: 15px;
  top: 20px;
  border-radius: 45px;
  background-color: #01a4e9;
  animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

.pulsating-circle2::after {
  content: "";
  position: absolute;
  left: 15px;
  top: 20px;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #ff0060;
  border-radius: 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}

.sliding::-webkit-scrollbar {
  /* width: 0; */
}

.leadheading {
  font-size: 52px;
  line-height: 120%;
  /* or 62px */

  letter-spacing: -0.03em;

  color: #000000;
}

.leadsubheading {
  font-weight: 600;
  font-size: 21px;
  line-height: 25px;

  color: #000000;
}

.leadcard {
  background: rgba(24, 1, 164, 0.1);
  backdrop-filter: blur(50px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 16px;
}

.leadnum {
  font-weight: 200;
  font-size: 60px;
  line-height: 73px;
  color: #000000;
}

.leadcardtext {
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
  color: #000000;
}

.leadcircle {
  width: 365px;
  height: 365px;
  background: rgba(45, 53, 255, 0.1);
  filter: blur(82px);
}

.leadcontact {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.005em;
  color: #000000;
}

.formdesc {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.005em;
}

.servicedescription {
  font-weight: 400;
  /* font-size: 16px; */
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.005em;
  color: #ffffff;
}

.italics {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  font-size: 60px;
  line-height: 120%;
  /* or 62px */

  letter-spacing: -0.03em;

  color: #0077fa;
}

.multiplier {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 62px;
  line-height: 120%;
  /* or 62px */

  letter-spacing: -0.03em;

  color: #af04ff;
}

.serviceformbg {
  background: rgba(34, 34, 34, 0.6);
  /* backdrop-filter: blur(50px); */
}

.serviceformbanner {
  background: rgba(44, 83, 54, 0.8);
  backdrop-filter: blur(35px);
}

.serviceformheading {
  font-weight: 600;
  /* font-size: 32px; */
  line-height: 140%;
  /* identical to box height, or 45px */

  color: #ffffff;
}

.headbox {
  width: 100%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  border-radius: 80px;
}

.headboxshadow {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
}

.seventeensix {
  font-weight: 600;
  font-size: 17px;
  line-height: 140%;
}

.twentyonefour {
  font-weight: 400;
  font-size: 21px;
  line-height: 140%;
}

.twentyonethree {
  font-weight: 300;
  font-size: 21px;
  line-height: 140%;
}

.twentysix {
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  /* or 28px */

  text-align: center;
  letter-spacing: -0.04em;
}

.fortysix {
  font-weight: 600;
  font-size: 40px;
  line-height: 140%;
  /* identical to box height, or 56px */

  text-align: center;
  letter-spacing: -0.04em;
}

.fortyeightsix {
  font-weight: 600;
  font-size: 48px;
  line-height: 140%;
  /* or 67px */

  text-align: center;
  letter-spacing: -0.04em;
}

.fontstyle2 {
  font-family: "Zhi Mang Xing";
  font-style: normal;
  font-weight: 400;
  font-size: 52px;
  line-height: 130%;
  color: #ffffff;
}

.noconhead1 {
  font-weight: 600;
  font-size: 40px;
  line-height: 140%;
  /* identical to box height, or 56px */
  margin-top: 90px;
  text-align: center;
  letter-spacing: -0.04em;
}

.noconhead2 {
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
  margin-top: 20px;
  color: #000000;
}

.noconhead3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;

  text-align: center;
  letter-spacing: -0.04em;
}

.noconhead4 {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
  opacity: 0.8;
}

.morehead1 {
  font-weight: 400;
  font-size: 21px;
  line-height: 140%;
}

.imgshadow {
  border: 4px solid #ffffff;
  filter: drop-shadow(0px 0px 25px rgba(0, 0, 0, 0.25));
}

.morecontainer {
  max-width: 1080px;
  height: 510px;
}

.moredivone {
  width: 60%;
  height: 100%;
  background: #262626;
}

.moredivtwo {
  width: 40%;
  height: 100%;
  background: #171717;
  color: #fff;
  padding: 20px 10px 20px 20px;
}

.moreaboutimg {
  height: 510px;
}

.belowthousand {
  display: none;
}

.abovethousand {
  display: block;
}

@media (max-width: 1000px) {
  .morecontainer {
    max-width: 1080px;
    height: 100%;
  }

  .moredivone {
    width: 100%;
    height: 100%;
    max-height: 530px;
  }

  .moredivtwo {
   
    width: 100%;
    height: 100%;
  }

  .moreaboutimg {
    height: 460px;
  }

  .belowthousand {
    display: block;
  }

  .abovethousand {
    display: none;
  }
}

@media (max-width: 620px) {
  .noconhead1 {
    font-weight: 600;
    font-size: 21px;
    line-height: 140%;
    /* or 29px */
    margin-top: 40px;
    text-align: center;
    letter-spacing: -0.04em;

    color: #000000;
  }

  .noconhead2 {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */

    text-align: center;
    letter-spacing: -0.04em;

    color: #000000;
    margin-top: 10px;
  }

  .noconhead3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #ffffff;

    letter-spacing: -0.04em;
  }

  .noconhead4 {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    /* or 15px */
    text-align: center;
    color: #ffffff;
    opacity: 0.8;
  }

  .morehead1 {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */

    text-align: center;

    color: #000000;
  }

  .leadheading {
    font-size: 32px;
    line-height: 120%;
    /* or 62px */

    letter-spacing: -0.03em;

    color: #000000;
  }

  .italics {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 400;
    font-size: 40px;
    line-height: 72px;
    /* or 62px */

    letter-spacing: -0.03em;

    color: #0077fa;
  }

  .multiplier {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 74.4px;
    /* or 62px */

    letter-spacing: -0.03em;

    color: #af04ff;
  }

  .leadnum {
    font-weight: 50;
    font-size: 32px;
    line-height: 73px;
    color: #000000;
  }

  .leadcardtext {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #000000;
  }

  .moredivtwo {
    justify-content: center;
    align-items: start;
    width: 100%;
    height: 100%;
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }

  80%,
  100% {
    opacity: 0;
  }
}

.box::-webkit-scrollbar {
  display: none;
}

@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.8);
  }
}
