.popupBackground {
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(17px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.prompt {
  border-radius: 12px;
  background: #fff;
  width: 100%;
  max-width: 343px;
  height: auto;
  flex-shrink: 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.prompt img {
  height: 25px;
  width: 75%;
}
.prompt h3 {
  color: #282828;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 140% */
}
.prompt p {
  color: #5f6980;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
}
.prompt button {
  border-radius: 6px;
  padding: 12px 28px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 175% */
  letter-spacing: -0.2px;
}
.cancel {
  color: #5f6980;
  border-radius: 6px;
  border: 2px solid #5f6980;
}

.install {
  background: #58b000;
  color: #fff;
}
