.carouselContainer {
  position: relative;
  height: 70vh;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

}

.carouselSlide {
  display: flex;
  gap: 10px;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}
.active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.fadeOut {
  opacity: 0;
}
.carouselSlide::-webkit-scrollbar {
  display: none;
}

.carouselSlide img {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  scroll-snap-align: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.carouselSlide img.active {
  opacity: 1;
}
.button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 100%;
  border: none;
  background-color: transparent;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  opacity: 100%;
}
.button[disabled] {
  opacity: 0%;
  cursor: not-allowed;
}
@media screen and (max-width: 768px) {
  .carouselContainer {
    height: 50vh;
  }
}
