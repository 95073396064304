.heading {
  color: #fff;
  text-align: center;
  font-family: "Spectral", serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 33.6px */
}
.heading2 {
  color: #fff;
  text-align: center;
  font-family: "Spectral", serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 33.6px */
  margin-bottom: 5px;
}
.subHeading2 {
  color: #fff;
  text-align: center;
  font-family: "Spectral", serif;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 15.6px */
}
.malayalam {
  font-family: "Noto Sans Malayalam", sans-serif !important;
}
.subHeading {
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 15.6px */
}
@media (max-width: 640px) {
}
