.errorOutline {
  border: 1px solid #fa6b6b;
}
.error {
  margin-top: 7px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
}
.placeholder::placeholder {
  font-style: 12px;
}
.table {
  margin-top: 10px;
}
.table td {
  padding: 5px 50px 5px 5px;
}
