.mainDiv {
  background: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(17px);
  backdrop-filter: blur(17px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  padding: 20px;
  text-align: center;
  z-index: 1000;
  overflow: auto;
}
.container {
  margin: auto;
  background: rgb(235, 235, 235);
  color: black;
  backdrop-filter: blur(60px);
  border-radius: 12px;
  width: 100%;
  max-width: 350px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  overflow: hidden;
}
.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  gap: 10px;
}
.header h2 {
  color: #2c3892;
  font-family: "Spectral", serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 29.4px */
  overflow-wrap: break-word;
  min-width: 0;
  text-align: left;
}
.header button {
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  padding: 10px;
}
.button {
  border-radius: 8px;
  background: #2c3892;
  padding: 12px 16px;
  /* width: 100%; */
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.32px;
}
.buttonSecondary {
  border-radius: 8px;
  background: #fff;
  padding: 12px 22px;
  /* width: 100%; */
  margin-left: auto;
  color: #2c3892;
  border: 1px solid #2c3892;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.32px;
}
.selectedProduct {
  border-radius: 12px;
  border: 1px solid #e4e7ec;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 16px 20px;
  margin-top: 20px;
}
.selectedProduct h4 {
  color: #161616;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  text-align: left;
}
.selectedProduct table {
  width: 100%;
  table-layout: auto;
}
.selectedProduct table td {
  padding: 5px;
  color: #161616;
  font-family: var(--fontOne);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 184.615% */
  text-align: left;
}
.container form {
  text-align: left;
}
.container form label {
  color: #000;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 138.462% */
}
.container form input {
  margin-top: 10px;
  font-size: 1rem;
  width: 100%;
  padding: 11px 28px;
  border-radius: 0.375rem;
  border: 2px solid rgba(124, 124, 124, 0.545);
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.container form select {
  background-color: #f6f6f6; /* White background */
  color: #161616;
  border-radius: 0.375rem;
  border: 2px solid rgba(124, 124, 124, 0.545);
  width: 100%;
  padding: 11px 28px;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.errorOutline {
  border: 1px solid #fa6b6b !important;
}
.error {
  margin-top: 7px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
}
.dropdownList {
  position: absolute;
  top: calc(100% - 3px);
  left: 0;
  width: 100%;
  background-color: #fff;
  filter: drop-shadow(rgba(0, 0, 0, 0.4) 2px 2px 2px);
  border-radius: 0 0 8px 8px;
  max-height: 150px; /* Adjust the max-height as needed */
  overflow-y: auto;
  text-align: left;
  border: 2px solid rgba(124, 124, 124, 0.545);
  border-top: none;
}
.dropdownList::-webkit-scrollbar {
  display: none;
}
/* Style each dropdown item */
.dropdownList > div {
  padding: 10px;
  cursor: pointer;
}

/* Highlight the selected item */
.dropdownList > div:hover {
  background-color: #f0f0f0;
}

.plusOrMinusButton {
  border: 1px solid rgba(44, 56, 146, 0.5);
  width: 25px;
  height: 25px;
}
