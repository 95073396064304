.ht {
  height: 510px !important;
}
.backgroundMe {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 800px) {
  .ht {
    height: 460px !important;
    border-radius: 20px;
  }
  .backgroundMe {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 11px;
  }
}
