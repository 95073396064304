@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Malayalam:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Spectral:wght@200;300;400;500;600;700;800&display=swap");

.heading1 {
  color: #000;
  text-align: center;
  font-family: "Spectral", serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 25.2px */
}
.form {
  margin-top: 18px;
  border-radius: 12px;
  background: #f7f7f7;
  padding: 20px 12px;
}
.form p {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 138.462% */
}
.malayalam {
  font-family: "Noto Sans Malayalam", sans-serif !important;
}
.form input {
  font-size: 1rem;
}
.errorOutline {
  border: 1px solid #fa6b6b;
  border-radius: 0.375rem;
}
.error {
  margin-top: 7px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
}
.placeholder::placeholder {
  font-style: 12px;
}
.table {
  margin-top: 10px;
  width: 100%;
}
.table td {
  padding: 5px 50px 5px 5px;
}
.personTable {
  width: 100%;
  table-layout: auto;
}
.personTable td {
  padding: 5px;
  font-family: var(--fontOne);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.country {
  border-right: 1px solid #c1c0c0;
  border-radius: 8px 0 0 8px;
  width: fit-content;
  height: 52px;
  background: #eeeeee;
  padding: 13px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.dropdownList {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  filter: drop-shadow(rgba(0, 0, 0, 0.4) 2px 2px 2px);
  border-radius: 0 0 8px 8px;
  max-height: 150px; /* Adjust the max-height as needed */
  overflow-y: auto;
  text-align: left;
  z-index: 10;
}
.phoneInput {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  outline: 2px solid transparent;
  outline-offset: 2px;
  border-width: 2px;
  border-radius: 0.375rem;
  background: #fff;
}
.phoneInput input {
  /* border: none;
  margin: 0;
  padding-right: 0;
  font-size: 16px;
  width: 100%;
  border-radius: 0 8px 8px 0; */
  font-size: 1rem;
}

.phoneInput input:first-child {
  width: 20%;
  border-radius: 0;
  padding-left: 12px;
  border-right: 1px solid #818181;
}
.searchBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 14px;
  gap: 12px;
  isolation: isolate;
  font-size: 1rem;
  /* className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none disabled:opacity-50 appearance-none${ */
  width: 100%;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.07);
  margin: 16px 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.searchBox > input::placeholder {
  color: #2c3892;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.26px;
}
.personTableOther {
  width: 100%;
  table-layout: auto;
  margin: 5px 0;
}
.personTableOther td {
  padding: 5px;
  font-family: var(--fontOne);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  border: 2px solid #6666661a;
  border-radius: 0.375rem;
}
.mainCategoty {
  color: #000;
  font-family: "Spectral", serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 25.2px */
}
.filter {
  display: flex;
  align-items: center;
  gap: 11px;
  overflow: auto;
  flex-wrap: wrap;
  padding-bottom: 24px;
  padding-right: 16px;
  position: sticky;
  top: 128px;
  background: #fff;
  padding-left: 12%;
  padding-right: 12%;
}
.filter::-webkit-scrollbar {
  display: none;
}
.filter button {
  border-radius: 44px;
  border: 1px solid rgba(44, 56, 146, 0.4);
  background: rgba(255, 255, 255, 0.6);
  padding: 8px 18px;
  color: #000;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 138.462% */
  white-space: nowrap;
}
.filter .selected {
  color: #fff;
  border: 1px solid #000;
  background: #191818;
}
.product {
  height: 100%;
  width: 100%;
  border-radius: 12px;
  border: 1px solid #e0e0e0;
}
.product h3 {
  color: #0c0c0c;
  font-family: "Spectral", serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
  /* word-break: break-all; */
  overflow-wrap: break-word;
}
.product button {
  border-radius: 58px;
  border: 1px solid #2c3892;
  background: none;
  padding: 10px 16px;
  width: fit-content;
  color: #2c3892;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.32px;
}
.product button:hover {
  color: #fff;
  background: #2c3892;
}
.product p {
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 25.2px */
  letter-spacing: -0.36px;
}
.summary {
  width: 100%;
  border-radius: 12px;
  background: #f7f7f7;
  padding: 20px 12px;
}
.summary h3 {
  color: #000;
  font-family: "Spectral", serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 25.2px */
}
.selectedProduct {
  border-radius: 12px;
  border: 1px solid #e4e7ec;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 16px 20px;
}
.selectedProduct h4 {
  color: #161616;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.selectedProduct table {
  width: 100%;
  table-layout: auto;
}
.selectedProduct table td {
  padding: 5px;
  color: #161616;
  font-family: var(--fontOne);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 184.615% */
}
.submitButton {
  border-radius: 8px;
  background: #2c3892;
  box-shadow: 0px 0px 14px 0px rgba(209, 54, 182, 0.3);
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.32px;
  width: fit-content;
  padding: 12px 22px;
  max-width: 300px;
}
.secondaryButton {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 14px 0px rgba(209, 54, 182, 0.3);
  color: #2c3892;
  border: 1px solid #2c3892;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.32px;
  width: fit-content;
  padding: 12px 16px;
  max-width: 300px;
}
@media (max-width: 640px) {
  .filter {
    flex-wrap: nowrap;
    padding-left: 16px;
    padding-right: 16px;
  }
  .table {
    margin-top: 10px;
    table-layout: auto;
    width: 100%;
  }
  .table td {
    padding: 5px;
    border-spacing: 0.5rem;
  }
  .submitButton {
    max-width: 100%;
  }
  .secondaryButton {
    max-width: 100%;
  }
}
