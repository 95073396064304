.posterGeneratedContainer {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(255, 255, 255, 1);
    /* justify-content: center; */
    max-width: 390px;
    min-height: 100vh;
    box-shadow: 0px 4px 100px 0px rgba(0, 0, 0, 0.3);
}

.posterGeneratedbgContainer {
    border-radius: 16px 16px 16px 16px;
    box-shadow: 0px 0px 40px 0px rgba(137, 137, 137, 0.24);
    position: relative;
    width: 100%;
    /* padding-top: 177.8%; */
    aspect-ratio: 1 / 1;
    /* height: 100vh; */
}

.copyLink {
    /* display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; */
    overflow: hidden;
    text-overflow: ellipsis;
    color: #787878;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    max-width: 250px;
}
.button {
    align-items: center;
    border-radius: 6px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, .1);
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    min-height: 40px;
    min-width: 180px;
    padding: 9px 20px;
    background-color: rgb(11,38,91);
    filter: drop-shadow(rgba(11,38,91, 0.6) 4px 4px 0px);
    font-size: 16px;
    line-height: 22px;
    font-style: normal;
    font-weight: 600;
   
}
.contestButtonChandrayan {
    align-items: center;
    border-radius: 6px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, .1);
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    min-height: 40px;
    min-width: 180px;
    padding: 9px 20px;
    background-color: rgb(11,38,91);
    filter: drop-shadow(rgba(11,38,91, 0.6) 4px 4px 0px);
    font-size: 16px;
    line-height: 22px;
    font-style: normal;
    font-weight: 600;
    position: absolute;
    bottom: 24px;
    left: 50%;
    translate: -50%;
}

.contestButtonOnam {
    align-items: center;
    border-radius: 6px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, .1);
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    min-height: 40px;
    min-width: 180px;
    padding: 9px 20px;
    background-color: rgb(87,46,8);
    filter: drop-shadow(rgba(87,46,8, 0.6) 4px 4px 0px);
    font-size: 16px;
    line-height: 22px;
    font-style: normal;
    font-weight: 600;
    position: absolute;
    bottom: 24px;
    left: 50%;
    translate: -50%;
}