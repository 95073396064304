.background {
  background: #fff;
  color: #000;
  box-shadow: 0px -4px 24px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  text-decoration: none;
  background: #2c3892;
  border-radius: 20px 20px 0px 0px;
  z-index: 99;
}
.innerContainer {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1280px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: min(187px, 15%);
  padding-left: min(187px, 15%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}

.innerContainer button {
  border-radius: 40px;
  border: 1px solid #fff;
  transition: background-color 0.2s ease-in;
  padding: 10px 12px 10px 20px;
  display: flex;
  align-items: center;
  gap: 6px;
  white-space: nowrap;
  color: #f9f9f9;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.26px;
}
.innerContainer h4 {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
}
.innerContainer p {
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  letter-spacing: -0.12px;
}
.bagButton {
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 40px;
  border: 1px solid rgba(58, 1, 151, 0.21);
  background: rgba(78, 4, 198, 0);
  backdrop-filter: blur(12px);
  transition: background-color 0.2s ease-in;
}
@media (max-width: 768px) {
  .innerContainer {
    padding: 16px;
    font-size: 12px;
  }
  .innerContainer button {
    padding: 8px 12px;
  }
  .bagButton {
    width: 32px;
    height: 32px;
    padding: 6 px;
    border-radius: 32px;
  }
}
