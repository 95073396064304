@import url('https://fonts.googleapis.com/css2?family=Carter+One&family=Oswald:wght@200..700&display=swap');
.fullme {
  position: relative;
  width: 100%;
  background-color: #0c0c0d;
  height: 100%;
  z-index: 10;
  padding-bottom: 30px;
}

.backMe {
  position: absolute;
  left: 0;
  top: 0;
  width: 44px;
  height: 44px;
  background: #000;
  padding: 10px;
  border-radius: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.backMe svg {
  width: 100%;
  height: 100%;
}
.backMe p {
  display: none;
}
.headerText {
  display: flex;
  gap: 10px;
  align-items: center;
}
.headerText h2 {
  font-family: var(--secondary-font);
  color: #cf0;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
}
.headerText h3 {
  /* font-family: var(--secondary-font); */
  font-family: "Oswald", sans-serif;
  color: #fff;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
}
.button {
  width: fit-content;
  background: #cf0;
  border-radius: 5px;
  filter: drop-shadow(rgba(204, 255, 0, 0.6) 4px 4px 0px);
  color: #000000;
  padding: 12px 24px;
  font-family: var(--primary-font);
  font-size: 1.3125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  text-transform: uppercase;
}
.galleryBorder {
  border: 1px solid rgba(205, 205, 205, 0.5);
  position: relative;
  width: 100%;
  height: 100%;
}
.flexBoxCourosel {
  /* margin: 16px auto 0 auto; */
  margin-top: 16px;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.flexOne {
  flex: 60%;
  background: #001e4b;
}

.flextwo {
  flex: 40%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 12px;
  padding: 20px 10px 20px 20px;
  background: #001254;
}

.detailsOfWhom {
  display: flex;
  justify-content: flex-start;
  gap: 17px;
  align-items: center;
}
.flextwo h1 {
  font-family: "Oswald", sans-serif;

  font-size: 3.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 3.25rem;
  color: #fff;
}
.flextwo p {
  font-family: var(--primary-font);
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: #fff;
}
.backtxt,
.backOff {
  display: none;
}
.showMoreMedia h1 {
  font-family: var(--secondary-font);
  font-size: 3.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 3.25rem;
  color: #fff;
}
/* talewind md device */
@media (max-width: 768px) {
  .headerText {
    display: flex;
    flex-direction: column;
    gap: 0;
    align-items: start;
    margin-top: 40px;
  }
  .headerText h2 {
    font-size: 1.5rem;
    line-height: 2.375rem;
  }
  .headerText h3 {
    font-size: 1.3125rem;
    line-height: 1.3125rem;
  }
  .button {
    padding: 10px 17px;
    font-size: 0.875rem;
  }
  .backMe {
    left: 20px;
    top: 0;
    padding: 6px 10px;
    width: fit-content;
    height: fit-content;
    gap: 10px;
  }
  .backMe svg {
    width: 14px;
    height: 14px;
  }
  .backMe p {
    font-family: var(--primary-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    color: #fff;
    display: block;
  }
  .flextwo h1 {
    font-size: 2rem;
    line-height: 2rem;
  }
  .flextwo p {
    font-size: 1.0625rem;
  }
  .showMoreMedia h1 {
    font-size: 2rem;
    line-height: 2rem;
  }
}
@media only screen and (max-width: 800px) {
  .backtxt {
    display: block;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
  }

  .backOff {
    position: relative;
    display: block;
    width: 17px;
  }

  .button {
    width: max-content;
    padding: 8px 16px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flexBoxCourosel {
    /* margin: 16px auto auto auto; */
    margin-top: 16px;

    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 0px;
    width: 100%;
    background: #0c0c0d;
  }

  .flexOne {
    flex: 65%;
  }

  .flextwo {
    flex: 35%;
  }
}
