.ralewayFont {
  text-align: center;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  line-height: 140%;
  /* 18.2px */
}

.secondsTimer {
  color: #2365e8;
  text-align: center;
  font-family: Inter;
  font-size: 162px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 194.4px */
  letter-spacing: -3.24px;
  opacity: 0.3;
}
