.nav {
  position: relative;
  width: 100%;
  height: 80px;
  padding: 28px 20px;
  top: 0px;
  /* background: linear-gradient(
    90.27deg,
    rgba(95, 0, 57, 0.3) 6.05%,
    rgba(28, 39, 80, 0.3) 98.64%
  ); */
  background-color: #58019d;
  backdrop-filter: blur(84px);
}
.shopBuutn {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 13px 14px;
  justify-content: center;
  gap: 8px;
  width: max-content;
  height: 44px;
  background: #090909;
  border: 1px solid #cecece;
  border-radius: 30px;
  margin-top: 8px;
}
.headingOFad {
  margin-top: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  color: #ffffff;
  text-align: center;
}
.textInadd {
  margin-top: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.8;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.moreDetails {
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  box-shadow: 0px 0px 15px rgb(0 0 0 / 25%);
  border-radius: 80px;
}

.navData {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100;
  text-align: center;

  position: relative;
}

.icons {
  /* width: 98px; */
  display: flex;
  justify-content: left;
  height: 34px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  color: #ffffff;
}

.donlode_app {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 20px;
  gap: 10px;
  right: 0px;
  width: 228px;
  height: 48px;
  font-family: Inter;
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  background: linear-gradient(90deg, #5073ef 0%, #6a0127 100%);
  border-radius: 100px;
}

.copy_app {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 12px 20px; */
  gap: 10px;
  right: 0px;
  width: 108px;
  height: 48px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  background: linear-gradient(90deg, #5073ef 0%, #6a0127 100%);
  border-radius: 100px;
}

.arow {
  position: absolute;
  right: 20px;
}

.container {
  /* padding-left: 121px;
  padding-right: 121px; */
  /* padding-top: 3.125vw; */
  background-color: #f0efef;
  /* max-width: 1280px; */
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;
}

.flexico {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.grids {
  width: 306px;
  height: 544px;
  max-height: 544px;
  background: black;
  border-radius: 10px;
  position: relative;
  margin-left: 21px;
  margin-top: 20px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.separator {
  opacity: 0.2;
  border: 1px solid #000000;
  margin-top: 41px;
  margin-bottom: 31px;
  width: 80%;
}

.card1 {
  width: 50%;
}

.grids1 {
  width: 100%;
  /* height: 544px; */
  height: 100%;
  /* max-height: 500px; */
  background: black;
  /* border-radius: 10px; */
  position: relative;
  /* margin-left: 21px;
  margin-top: 20px; */
  display: flex;
  flex-direction: column;
  overflow: hidden;
  /* -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px; */
}

.grids3 {
  width: 306px;
  height: 544px;
  max-height: 544px;
  background: black;
  border-radius: 10px;
  position: relative;
  margin-left: 21px;
  margin-top: 20px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.showOnMobile,
.showOnMobile1,
.showOnMobile2 {
  display: none;
}

.fileDisplay {
  height: 100%;
  /* max-height: 500px; */
  width: 100%;
  /* overflow: hidden; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-top: 20px; */
  /* object-fit: fit; */
}

.posterData_3 {
  position: absolute;
  /* width: 306px;
    height: 490px; */
  top: 73px;
  margin-top: 110px;
  padding-left: 16px;
  padding-right: 16px;
  /* padding-top: 16px; */
}

.gradeffect {
  position: absolute;
  width: 306px;
  height: 439px;
  z-index: 2;
  bottom: 62px;
  /* background-color: #001561; */
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 27.62%);
  border-radius: 0px 0px 10px 10px;
}

.participer {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 27px;
  /* or 129% */
}

.about {
  /* width: 273px; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.about2 {
  /* width: 273px; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0px;
}

.clickhere {
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.imgx {
  width: 100%;
  height: 260px;
  opacity: 0.9;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
}

.posterData {
  padding-left: 16px;
  padding-top: 20px;
  padding-right: 16px;
}

.campaign {
  margin-top: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 140%;
}

.description {
  margin-top: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  color: #787878;
  max-height: 100px;
  overflow: hidden;
}

.org {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  margin-bottom: 0;
  color: #ffffff;
}

.eli {
  /* padding: 6px 12px; */
  margin-top: 30px;
  width: 100%;
  height: 32px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2f2f2f;
  border-radius: 30px;
}

.elitext {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  color: #eaeaea;
}

.txt_arrow {
  /* position: absolute; */
  margin-left: 5px;
  margin-top: 3px;
}

.imgx_3rd {
  width: 100%;
  object-fit: cover;
  height: 201px;
  left: 814px;
  top: 146px;
  opacity: 0.9;
  border-radius: 10px 10px 0px 0px;
}

.buton {
  position: absolute;
}

.elix {
  margin-top: 37px;
  width: 100%;
  /* height: 32px; */
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.bottom_button {
  display: flex;
  /* justify-content: center; */
  /* margin-left: 16px; */
}

.participate {
  background: linear-gradient(92.38deg, #001561 0%, #6f0043 98.8%);
}

.bottomnav {
  width: 100%;
  height: 61px;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 20px 75px;
  position: absolute;
  bottom: 0px;
  background: linear-gradient(92.38deg, #001561 0%, #6f0043 98.8%);
  border-radius: 0px 0px 10px 10px;
}

.linki {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #787878;
}

/* .buttonshare {
    width: 50px;
    height: 50px;    
    background: #252525;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 100px;
} */

.propic {
  width: 40px;
  height: 40px;
  border: 2px solid #171717;
  border-radius: 100px;
  margin-right: 10px;
}

.flexiLikview {
  display: flex;
  /* justify-content: space-between !important; */
}

.maincard {
  margin-top: 10px;
  height: 544px;
  width: 612px;
  border-radius: 20px;
}

.candidateLikes {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.005em;

  color: #000000;
}

.candidateName {
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.005em;

  color: #000000;
}

.buttonText {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #ffffff;
}

.buttonTextBlack {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #000000;
}

.view {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.005em;
  margin-left: 10px;

  color: #787878;
}

.belowflex {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.leftico {
  margin-left: 10px;
}

.campaign:hover {
  color: #001561;
}

.owner {
  /* margin-top: 80px; */
  margin-top: 24px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #787878;
  text-align: center;
}

.ownerz {
  margin-left: 5px;
  margin-top: 80px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #787878;
  text-align: center;
}

.ownerz:hover {
  color: #001561;
}

.participate {
  margin-top: 11px;
  text-decoration: underline;
  cursor: pointer;
}

.sponserParent {
  font-family: "Inter", sans-serif;
  gap: 8px;
  display: grid;
  grid-template-columns: auto auto auto;
}

.sponserDiv {
  /* aspect-ratio: 1;  */
  background: white;
}

.sponserVideo {
  aspect-ratio: 1;
  object-fit: cover;
}

.sponserName {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
}

.sponserTagline {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
}

.viewsMobile {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0px;
}

.error {
  margin-top: 7px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
}
.errorOutline {
  border: 1px solid #fa6b6b;
}
.placeholder::placeholder {
  font-style: 12px;
}
.form select {
  background: #d4d4d4;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  outline: none;
}
.viewPill {
  position: absolute;
  right: 16px;
  top: 16px;
  border-radius: 46px;
  background: rgba(70, 70, 70, 0.4);
  backdrop-filter: blur(50px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  z-index: 1;
}
.contestCard {
  background: rgba(43, 43, 43, 0.6);
  border-radius: 6px;
  display: flex;
  padding: 8px;
  gap: 12px;
  box-shadow: 4px 4px 3px 1px rgba(0, 0, 0, 0.25);
  align-items: center;
}
.contestCardName {
  font-family: var(--fontOne);
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  opacity: 0.8;
}
.likedButton {
  /* box-shadow: 3px 3px 1px 0px #e07578 !important; */
  box-shadow: none !important;
  background: none !important;
  padding: 12px 5px !important;
}

.button {
  border-radius: 30px;
  background: #262626;
  /* min-width: 100px; */
  box-shadow: 3px 3px 1px 0px rgba(0, 0, 0, 0.25);
  padding: 14px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  transition: all 0.3 ease-in-out;
}
.shareButton {
  border-radius: 30px;
  background: #262626;
  /* min-width: 100px; */
  box-shadow: 3px 3px 1px 0px rgba(0, 0, 0, 0.25);
  padding: 14px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  transition: all 0.3 ease-in-out;
}
.button:disabled,
.likedButton:disabled,
.shareButton:disabled {
  opacity: 0.7;
}
.loader {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-top: 3px solid #4b4b4b;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.textLargeMedium {
  font-family: var(--fontOne);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
}
.textDescriptionHeading {
  font-family: var(--fontOne);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}
.textDescription {
  font-family: var(--fontOne);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.certificateDiv {
  border-radius: 15px;
  background: #090909;
  box-shadow: 4px 4px 3px 1px rgba(44, 42, 42, 0.25);
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 12px 30px;
  margin-left: auto;
  text-align: center;
  cursor: pointer;
  gap: 5px;
}
.certificateDiv p {
  font-family: var(--primary-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
}
/* talewind md device */
@media (max-width: 768px) {
  .button {
    padding: 14px;
  }
  .button p {
    font-size: 15px;
    font-weight: 400;
  }
  .shareButton {
    padding: 14px;
  }
  .shareButton p {
    font-size: 15px;
    font-weight: 400;
  }
  .likedButton {
    padding: 7px 5px;
  }
  .textLargeMedium {
    font-size: 16px;
  }
  .textDescriptionHeading {
    font-size: 15px;
    line-height: 22px;
  }
  .textDescription {
    font-size: 13px;
    line-height: 22px;
  }
  .certificateDiv {
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 450px;
  }
}
@media (max-width: 360px) {
  .shareButton {
    display: none;
  }
}
@media (max-width: 620px) {
  .maincard {
    margin-top: 0px;
    width: 100%;
    height: 500px;
    border-radius: 0px;
    padding: 0px;
  }

  .grids1 {
    width: 100%;
    /* height: 544px; */
    height: 100%;
    max-height: 500px;
    background: black;
    /* border-radius: 10px; */
    position: relative;
    /* margin-left: 21px;
    margin-top: 20px; */
    display: flex;
    flex-direction: column;
    overflow: hidden;
    /* -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px; */
  }

  .mobileHide {
    display: none !important;
  }

  .card1 {
    width: 100%;
  }

  .showOnMobile2 {
    display: block !important;
    width: 100%;
  }

  .nav {
    position: relative;
    width: 100%;
    height: 80px;
    padding: 20px 20px;
    top: 0px;
    /* background: linear-gradient(
      90.27deg,
      rgba(95, 0, 57, 0.3) 6.05%,
      rgba(28, 39, 80, 0.3) 98.64%
    ); */
    background-color: #58019d;
    backdrop-filter: blur(84px);
  }

  .fileDisplay {
    /* height: 100%; */
    /* padding-top: 20px; */
    max-height: 460px;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: fit;
  }
}

.adsParent {
  /* padding-left: 100px; */
  /* padding-right: 100px; */
}
.reactPlayer {
  overflow: hidden;
  border-radius: 20px 20px 0 0;
  object-fit: contain;
}
@media (max-width: 970px) {
  .adsParent {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (max-width: 582px) {
  /* .container {
    padding-left: 16px !important;
    padding-right: 16px !important;
    padding-top: 3.125vw !important;
  } */

  .campaign1 {
    margin-top: 0;
    font-family: "Inter";
    font-style: normal;
    /* font-weight: 600; */
    /* font-size: 18px; */
    text-align: center;
    /* line-height: 140%; */
  }

  /* .nav {
    position: relative;
    width: 100%;
    height: 63px;
    padding: 8px 100px;
    top: 0px;
    background: linear-gradient(90.27deg, rgba(95, 0, 57, 0.3) 6.05%, rgba(28, 39, 80, 0.3) 98.64%);
    -webkit-backdrop-filter: blur(84px);
    backdrop-filter: blur(84px);
  } */

  /* .grids,
  .grids1,
  .grids3 {
    max-width: 358px;
    width: 100%;
    height: 544px;
    max-height: 544px;
    background: black;
    border-radius: 10px;
    position: relative;
    margin-left: 0px;
    margin-top: 20px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  } */

  .participate {
    margin-top: 22px;
    text-decoration: underline;
    cursor: pointer;
  }

  .participer1 {
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;
    display: flex;
    align-items: center;
    letter-spacing: -0.005em;
  }

  .posterData_3 {
    margin-top: 20px;
    position: relative;
    top: 0;
  }

  .imgx {
    height: 156px;
  }

  .description {
    margin-top: 5px;
  }

  .grids {
    height: 430px;
  }

  /* .grids3 {
        width: 100% !important;
        margin-left: auto !important;
        margin-right: auto !important;
        margin-top: 20px !important;
        
    } */

  .flexico {
    display: flex;
    flex-direction: space-around !important;
  }

  .grids3,
  grids {
    height: 340px !important;
    /* display: none; */
    /* margin-top: 50px !important; */
  }

  /* .showOnMobile {
        display: block !important;
    } */
  .gradeffect {
    display: none;
  }

  /* .about {
        display: none;
    } */

  .showOnMobile {
    display: block !important;
    width: 100%;
    position: absolute;
    bottom: 0px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .showOnMobile1 {
    display: block !important;
    width: 100%;
    /* position: absolute; */
    bottom: 0px;
    padding-left: 16px;
    padding-right: 16px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) -21.17%, #000000 100%);
  }

  .elix {
    margin-top: 20px;
    margin-bottom: 20px;
    /* margin-left: 16px;
        margin-right: 16px; */
    justify-content: space-between;
  }

  .sponserParent {
    gap: 16px;
    grid-template-columns: auto;
  }

  .sponserDiv {
    margin-left: 0;
    width: 100%;
    height: auto;
    aspect-ratio: auto;
  }

  .sponserCoverImage {
    aspect-ratio: 1;
    height: auto;
  }

  .adsParent {
    padding-left: 16px;
    padding-right: 16px;
  }

  .serviceCard {
    min-width: 330px;
    width: 330px;
  }

  .reviewParent {
    padding-left: 16px;
  }

  .servicesParent {
    padding-left: 16px;
  }
}

@media screen and (max-width: 895px) {
  grid1,
  .grid3 {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1221px) {
  .grid3 {
    margin-top: 20px;
  }
}

.popupBackground {
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(17px);
}
.SharePopupParent {
  display: flex;
  flex-direction: column;
  background: rgba(37, 37, 37, 0.6);
  backdrop-filter: blur(60px);
  border-radius: 12px;
  z-index: 15000;
  color: #ffffff;
  height: auto;
  width: 100%;
  max-width: 350px;
}

@media screen and (max-width: 582px) {
  .container {
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 3.125vw !important;
  }

  .grids {
    width: 100% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 20px;
  }

  .flexico {
    display: flex;
    flex-direction: column !important;
  }
}

.nav {
  position: relative;
  width: 100%;
  height: 96px;
  padding: 24px 100px;
  top: 0px;
  background: linear-gradient(
    90.27deg,
    rgba(95, 0, 57, 0.3) 6.05%,
    rgba(28, 39, 80, 0.3) 98.64%
  );
  backdrop-filter: blur(84px);
}

.navData {
  display: flex;
  align-items: center;
  height: 48px;

  position: relative;
}

.icons {
  /* width: 98px; */
  display: flex;
  justify-content: left;
  height: 34px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  color: #ffffff;
}

.donlode_app {
  display: flex;
  flex-direction: row;

  align-items: center;
  padding: 12px 20px;
  gap: 10px;
  position: absolute;
  right: 0px;
  width: 228px;
  height: 48px;
  font-family: Inter;
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  background: linear-gradient(90deg, #5073ef 0%, #6a0127 100%);
  border-radius: 100px;
}
.copy_app {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 12px 20px; */
  gap: 10px;
  right: 0px;
  width: 108px;
  height: 48px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  background: linear-gradient(90deg, #5073ef 0%, #6a0127 100%);
  border-radius: 100px;
}

.arow {
  position: absolute;
  right: 20px;
}

.container {
  padding-left: 121px;
  padding-right: 121px;
  padding-top: 3.125vw;
}

.flexico {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.grids {
  width: 306px;
  height: 544px;
  background: black;
  border-radius: 10px;
  position: relative;
  margin-left: 21px;
}

.posterData_3 {
  position: absolute;
  padding-left: 16px;
  padding-right: 16px;
  top: 139px;
}

.participer {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 27px;
  color: #ffffff;
  margin-bottom: 0;
}

.about {
  /* width: 273px; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  color: #787878;
}

.imgx {
  width: 100%;
  height: 280px;
  opacity: 0.9;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
}

.posterData {
  padding-left: 16px;
  padding-top: 20px;
  padding-right: 16px;
}

.campaign {
  margin-top: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
}

.description {
  margin-top: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  color: #787878;
}

.org {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  margin-bottom: 0;
  color: #ffffff;
}

.eli {
  /* padding: 6px 12px; */
  margin-top: 30px;
  width: 100%;
  height: 32px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2f2f2f;
  border-radius: 30px;
}

.elitext {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  color: #eaeaea;
}

.txt_arrow {
  /* position: absolute; */
  margin-left: 5px;
  margin-top: 3px;
}

.imgx_3rd {
  width: 100%;
  object-fit: cover;
  height: 201px;
  left: 814px;
  top: 146px;
  opacity: 0.9;
  border-radius: 10px 10px 0px 0px;
}

.elix {
  margin-top: 37px;
  width: 100%;
  height: 32px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.bottom_button {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.bottomnav {
  width: 100%;
  height: 61px;
  text-align: center;
  padding: 20px 75px;
  position: absolute;
  bottom: 0px;
  background: linear-gradient(92.38deg, #001561 0%, #6f0043 98.8%);
  border-radius: 0px 0px 10px 10px;
}

.linki {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #787878;
}

/* .buttonshare {
    width: 50px;
    height: 50px;    
    background: #252525;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 100px;
} */

.propic {
  width: 50px;
  height: 50px;
  border: 2px solid #171717;
  border-radius: 100px;
}

.flexiLikview {
  display: flex;
  justify-content: space-between !important;
}

.candidate {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: -0.005em;
  color: #eaeaea;
}

.view {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: -0.005em;
  color: #787878;
  margin-left: 10px;
}
.belowflex {
  display: flex;
  justify-content: center;
}
.leftico {
  margin-left: 10px;
}
.campaign:hover {
  color: #001561;
}
.owner {
  margin-top: 80px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #787878;
  text-align: center;
}
.ownerz {
  margin-left: 5px;
  margin-top: 80px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #787878;
  text-align: center;
}
.ownerz:hover {
  color: #001561;
}

.detailsPopupBackground {
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(17px);
}
.detailsPopupParent {
  display: flex;
  flex-direction: column;
  background: #fff;
  backdrop-filter: blur(60px);
  border-radius: 12px;
  z-index: 15000;
  color: #ffffff;
  height: auto;
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 20px;
}
.couponsParent h3 {
  color: #000;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
