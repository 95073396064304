.mainDiv {
  background: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(17px);
  backdrop-filter: blur(17px);
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  padding: 40px 20px;
  text-align: center;
  z-index: 1;
}

.phoneInput {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  outline: 2px solid transparent;
  outline-offset: 2px;
  border-width: 2px;
  border-radius: 0.375rem;
  background: #fff;
}
.phoneInput input {
  /* border: none;
  margin: 0;
  padding-right: 0;
  font-size: 16px;
  width: 100%;
  border-radius: 0 8px 8px 0; */
  font-size: 1rem;
  color: black;
}

.phoneInput input:first-child {
  width: 25%;
  border-radius: 0;
  padding-left: 12px;
  border-right: 1px solid #818181;
}
.placeholder::placeholder {
  font-size: 12px;
}
