.layout {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 2.7% 0 32px 0;
  display: flex;
  gap: 1.67%;
}
.layout h1 {
  font-family: var(--secondary-font);
  font-size: 3.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 3.25rem;
}

.callout1 {
  font-family: var(--secondary-font);
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}
.subtextMedium {
  font-family: var(--primary-font);
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
}
.moreButtonDescription {
  font-family: var(--primary-font);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.moreButton {
  padding: 4px 0;
  border-bottom: 2px solid #d8da31;
  font-family: var(--primary-font);
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  text-transform: uppercase;
}
.buttonText {
  font-family: var(--primary-font);
  font-size: 1.3125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  text-transform: uppercase;
}
.moreDetailsOnTop {
  width: fit-content;
  color: #fff;
  border: 1px solid;
  white-space: nowrap;
  background: rgba(277, 277, 277, 0.2);
}
.sharebutton {
  display: none;
}
.logo {
  /* border-radius: 78px; */
  width: 78px;
  height: 78px;
  /* border: 3px solid #464646; */
  object-fit: contain;
}
.left {
  padding: 3% 4% 3% 0;
  flex-basis: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.registerButton {
  background: #cf0;
  /* transform: rotate(-7deg); */
  filter: drop-shadow(rgba(204, 255, 0, 0.6) 4px 4px 0px);
  transform-style: preserve-3d;
  transform-origin: center center;
  width: fit-content;
  border-radius: 5px;
  padding: 15px 33px;
}

.right {
  background: #000690;
  flex-basis: 70%;
  padding: 4% 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.loader {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-top: 3px solid #fff;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* talewind md device */
@media (max-width: 768px) {
  .layout {
    padding: 0;
    flex-direction: column;
    gap: 0;
  }
  .layout h1 {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
  .left {
    flex-basis: auto;
    height: fit-content;
    padding: 36px 20px 24px 20px;
  }
  .right {
    flex-basis: auto;
    height: 74vh;
    transition: all 1s ease-in;
  }
  .logo {
    /* border-radius: 40px; */
    width: 40px;
    height: 40px;
    /* border: 3px solid #464646; */
  }
  .callout1 {
    font-size: 1.125rem;
  }
  .subtextMedium {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
  .buttonText {
    font-size: 0.875rem;
  }
  .registerButton {
    padding: 12px 18px;
  }
  .sharebutton {
    border-radius: 12px;
    background: #262626;
    box-shadow: 3px 3px 1px 0px rgba(0, 0, 0, 0.25);
    padding: 12px;
    display: flex;
    gap: 6px;
    align-items: center;
    z-index: 1;
    margin-left: auto;
  }
  .sharebutton svg {
    height: 15px;
    width: 24px;
  }
}
