@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital@1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Covered+By+Your+Grace&family=Zhi+Mang+Xing&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  --fontOne: "Inter", sans-serif;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  --secondary-color: black;
  --third-color: white;
  --primary-font: "Inter", sans-serif;
  --secondary-font: "Koulen", cursive;
  --third-font:'Bangers', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
  color: #eaeaea;
  font-family: "Inter";
}
