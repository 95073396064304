.banner {
  color: #000;
  text-align: center;
  font-family: Times;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 134%; /* 28.14px */
  background: #f1da18;
  width: 100%;
  padding: 10px;
  display: flex;
  overflow: hidden; /* Hide overflow for scrolling */
}

.content-wrapper {
  display: flex; /* Flex container for both contents */
  animation: scroll 20s linear infinite; 
}

.content {
  display: inline-block;
  white-space: nowrap; /* Prevent line breaks */
  padding-right: 50px; /* Add space between items */
}

@keyframes scroll {
  0% {
    transform: translateX(0); /* Start from the left */
  }
  100% {
    transform: translateX(-50%); /* Move left by half the width */
  }
}
