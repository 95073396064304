.loading {
  width: 100px;
  height: 100px;
  min-width: 20px;
  min-height: 20px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.loading-circle {
  width: 100px;
  height:100px;
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  border: 3px solid #f2f2f2;
  border-top-color: rgb(145, 77, 255);
  animation: loading-animation 1.2s linear infinite;
}

@keyframes loading-animation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 640px){
  .loading {
    width: 50px;
    height: 50px;
  }
  .loading-circle {
    width: 50px;
    height: 50px;
  }
}
