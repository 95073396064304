/* CouponTemplate1 */
.couponParent1 {
  width: 300px;
  flex-shrink: 0;
  border-radius: 9px;
  overflow: hidden;
  background: #fff;
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.25);
}
.couponParent1 .limitedOffer {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 4px;
  border: 1px solid #fff;
  background: #4fd200;
  padding: 10px;
  width: fit-content;
}
.couponParent1 h3 {
  color: #000;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.couponParent1 h4 {
  color: #535353;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.couponParent1 h4 span {
  font-weight: 400;
}
.couponParent1 p {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.couponParent1 button {
  border-radius: 5px;
  border: 1px solid #919191;
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  width: 100%;
  padding: 18px 0;
  margin-top: 10px;
}
.loader {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 auto;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-top: 3px solid #919191;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
