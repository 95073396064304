.popupBackground {
  background: rgba(18, 18, 18, 0.44);
  display: flex;
  align-items: center;
  justify-content: center;
}

.parentDiv {
  border-radius: 18px;
  background: #fff;
  width: 100%;
  max-width: 430px;
  height: auto;
  flex-shrink: 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
}
.parentDiv a:hover {
  color: #56565d;
}
.parentDiv h1 {
  color: #313135;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 130% */
}
.parentDiv h2 {
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  width: 100%;
  padding: 5px;
}
.parentDiv h3 {
  color: #56565d;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.parentDiv p {
  color: #56565d;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.parentDiv .copy {
  border-radius: 4px;
  background: #d7ecca;
  padding: 5px 16px;
  color: #313135;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: -0.16px;
  white-space: nowrap;
  width: fit-content;
  cursor: pointer;
}
.parentDiv button {
  border-radius: 1000px;
  width: 100%;
  padding: 11px 0;
  background: #f2f2f4;
  color: #313135;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: -0.16px;
}
