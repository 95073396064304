* {
  margin: 0;
  padding: 0;
}
.App {
  background-color: #e8f5fa;
  background-image: url("/public/worldBg.png"); /* max-width: 1280px; */
  margin-left: auto;
  margin-right: auto;
  background-position: center;
  background-attachment: fixed;
}
a {
  text-decoration: none;
}

a:hover {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
pre {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  white-space: pre-wrap;
  font-family: var(--fontOne);
}
.animate-pulse {
  animation: pulse 1s infinite alternate;
}
summary {
  list-style: none;
}
summary::-webkit-details-marker {
  display: none;
}
@keyframes pulse {
  0% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}
.text-large {
  font-family: var(--fontOne);
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}
.text-large-medium {
  font-family: var(--fontOne);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
}
.text-regular {
  font-family: var(--fontOne);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}
.text-regular-medium {
  font-family: var(--fontOne);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
.text-regular-callout {
  font-family: var(--fontOne);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}
.text-small {
  font-family: var(--fontOne);
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}
.text-small-bold {
  font-family: var(--fontOne);
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
}
.text-smallest {
  font-family: var(--fontOne);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
.text-smallest-bold {
  font-family: var(--fontOne);
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 18px;
}
.text-description-heading {
  font-family: var(--fontOne);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}
.text-description {
  font-family: var(--fontOne);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
.malayalam {
  font-family: "Noto Sans Malayalam", sans-serif !important;
}
.text_Ell {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text_4Line {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text_1Line {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text_5Line {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.seperator {
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
  height: 0px;
}
.seperator-black {
  border: 1px solid rgba(89, 89, 89, 0.1);
  width: 100%;
  height: 0px;
  border-radius: 100%;
}
input:focus {
  /* border: 1px solid rgb(0, 0, 0); */
  outline-style: none !important;
}
textarea:focus {
  /* border: 1px solid rgb(0, 0, 0); */
  outline-style: none !important;
}
.bigWidthController {
  margin-left: auto;
  margin-right: auto;
  padding-left: 100px;
  padding-right: 100px;
}

.helpController {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  background-color: black;
}
.fifaHead {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: #000000;
}
.flexio {
  display: flex;
  align-items: center;
}
.help {
  transition: 0.2s;
  bottom: 1.2rem;
  right: 1.5rem;
  z-index: 11;
  position: fixed;
}
.thegrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.flexico {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.mobWidth {
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
  backdrop-filter: blur(50px);
}
.desktopWidthController {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
  backdrop-filter: blur(50px);
}
.fullWidthController {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
  backdrop-filter: blur(50px);
}
.widthController {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  min-height: 100vh;
  background-color: black;
}
.noDesk {
  display: none;
}
/* .container{
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  
} */
.yesToMobile {
  display: none;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.carousel-dot {
  width: 18px;
  height: 3px;
  background: #868585;
  border-radius: 10px;
  transition: all 0.3s ease-in;
}
.carousel-dot-active {
  width: 6px;
  width: 18px;
  height: 3px;
  background: #ffffff;
  border-radius: 10px;
}
@media screen and (min-device-width: 481px) and (max-device-width: 864px) {
  .help {
    transition: 0.2s;
    bottom: 1.2rem;
    right: 1.5rem;
    z-index: 11;
    position: fixed;
  }
}
@media screen and (min-device-width: 864px) and (max-device-width: 1024px) {
  .help {
    transition: 0.2s;
    bottom: 8rem;
    right: 7.5rem;
    z-index: 11;
    position: fixed;
  }
}
@media screen and (max-width: 480px) {
  .SharewidthController {
    margin-left: auto;
    margin-right: auto;
    max-width: 480px;
  }
  .roundedMeRad {
    border-radius: 50px !important;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .help {
    transition: 0.2s;
    bottom: 5.2rem;
    right: 1.5rem;
    z-index: 11;
    position: fixed;
  }
  .noMobie {
    display: none;
  }
  .flexico {
    flex-direction: column;
  }
  .noMob {
    display: none;
  }
  .noDesk {
    display: block;
  }
  .yesToMobile {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    padding-top: 0px;
  }
}
@media screen and (min-device-width: 481px) and (max-device-width: 1024px) {
  .SharewidthController {
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
  }
  .flexico {
    flex-direction: column;
  }
  .noDesk {
    display: block;
  }
  .noMobie {
    display: flex;
  }
  .noMob {
  }
}
