.container {
  padding: 30px 16px;
  background: #fff;
  color: #0c0c0c;
  backdrop-filter: blur(60px);
  border-radius: 12px;
  height: fit-content;
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  gap: 15px;
}
.container img {
  width: 150px;
  object-fit: cover;
  aspect-ratio: 1;
}
.container button {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 14px 0px rgba(209, 54, 182, 0.3);
  color: #2c3892;
  border: 1px solid #2c3892;
  width: fit-content;
  margin: auto auto 0;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.32px;
  padding: 12px 20px;
}

.table {
  width: 70%;
  table-layout: auto;
}
.table td:first-child {
  text-align: left;
}
.table td:last-child {
  text-align: right;
}
.table td {
  padding: 10px 5px;
}

@media (max-width: 768px) {
  .container {
    padding: 16px;
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }
  .container img {
    width: 75px;
  }
  .container button {
    width: 80%;
  }
  .table {
    width: 100%;
  }
}
