.carouselContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.carouselSlide {
  display: flex;
  gap: 10px;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}

.carouselSlide::-webkit-scrollbar {
  display: none;
}

.carouselSlide img {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  scroll-snap-align: center;
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
}

.carouselSlide img.active {
  opacity: 1;
}

.carouselSlide .bgBlur {
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  background: rgba(61, 61, 61, 0.6);
  height: 100%;
  width: 100%;
}
.button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  border: none;
  background-color: transparent;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  opacity: 100%;
}
.button img {
  width: 24px;
  height: 24px;
  opacity: 70%;
}
.button[disabled] {
  opacity: 0%;
  cursor: not-allowed;
}
