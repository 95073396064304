.heading {
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
.couponParent {
  border-radius: 8px;
  border: 1px solid #c7c7c7;
  background: #fff;
  width: 100%;
  padding: 16px;
}
.couponParent h1 {
  color: #000;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.couponParent p {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
}

.coupon {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #000;
  padding: 18px 0;
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.saveCoupon {
  width: 100%;
  padding: 20px 30px;
  background: #fff;
  position: fixed;
  box-shadow: 0 -4px 24px 0 rgba(0, 0, 0, 0.1);
  bottom: 0;
  left: 0;
  z-index: 101;
  display: flex;
  align-items: center;
  gap: 13px;
}
.saveCoupon button {
  border-radius: 23px;
  background: #fdb087;
  padding: 15px;
  width: 100%;
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.cartBanner {
  width: 100%;
  padding: 16px 25px;
  background: #fff;
  box-shadow: 0 -4px 24px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
}
.cartBanner button {
  border-radius: 23px;
  background: #fdb087;
  padding: 14px 12px;
  width: fit-content;
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

/* CouponTemplate1 */
.selectedCoupon {
  width: 100%;
  flex-shrink: 0;
  border-radius: 9px;
  overflow: hidden;
  background: #fff;
}

.selectedDetails h3 {
  color: #56565d;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.selectedDetails p {
  color: #56565d;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.selectedDetails a {
  color: #56565d;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.couponParent1 {
  width: 100%;
  flex-shrink: 0;
  border-radius: 9px;
  overflow: hidden;
  background: #fff;
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.25);
}
.limitedOffer {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 4px;
  border: 1px solid #fff;
  background: #4fd200;
  padding: 10px;
  width: fit-content;
}
.couponParent1 h3 {
  color: #000;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.couponParent1 h4 {
  color: #535353;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.couponParent1 h4 span {
  font-weight: 400;
}
.couponParent1 p {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.couponParent1 button {
  border-radius: 5px;
  border: 1px solid #919191;
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  width: 100%;
  padding: 18px 0;
  margin-top: 10px;
}
.popupBackground {
  background: rgba(18, 18, 18, 0.44);
  display: flex;
  align-items: center;
  justify-content: center;
}
.copyCoupons {
  border-radius: 18px;
  background: #fff;
  width: 100%;
  max-width: 367px;
  height: auto;
  flex-shrink: 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.copyCoupons h3 {
  color: #313135;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 130% */
}
.copyCoupons button {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  border-radius: 5px;
  background: #eaeaea;
  padding: 10px 40px;
}
/* TALEWIND SM  */

@media (max-width: 640px) {
  .cartBanner button {
    width: 100%;
  }

  .heading {
    font-size: 16px;
    font-weight: 500;
  }
}
