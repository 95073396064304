.title {
  color: #fff;
  text-align: center;
  font-family: var(--third-font);
  font-size: 3.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.065rem;
}
.desciption {
  color: #dfdfdf;
  text-align: center;
  font-family: Inter;
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  max-width: 534px;
}
.viewGoviral {
  background: #fb6c44;
  transform: rotate(-7deg);
  color: #fff;
  font-family: var(--primary-font);
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  padding: 19px 28px;
  text-transform: uppercase;
}
.mailDesciption {
  color: #dfdfdf;
  text-align: center;
  font-family: var(--template-secondary-font);
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 30.8px */
  letter-spacing: 0.66px;
  text-transform: capitalize;
}
.link {
  font-family: var(--template-secondary-font);
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  display: flex;
  align-items: center;
  gap: 7px;
  color: #a2c4cd;
}
.link svg {
  fill: #a2c4cd;
}
/* talewind md device */
@media (max-width: 768px) {
  .title {
    font-size: 2rem;
    letter-spacing: 0.04rem;
  }
.viewGoviral{
    font-size: 0.875rem;
    padding: 12px 17px;
}
.mailDesciption {
  font-size: 15px;
}
.link {
  font-size: 14px;
}
}
