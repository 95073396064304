.mainDiv {
  background: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(17px);
  backdrop-filter: blur(17px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  z-index: 1000;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  backdrop-filter: blur(60px);
  border-radius: 12px;
  color: #000000;
  text-align: center;
  max-width: 520px;
  width: 100%;
  padding: 0 20px 20px 20px;
  height: fit-content;
}
.button {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 10px 45px;
  width: 75%;
  margin: 0 auto;
  color: black;
  white-space: nowrap;
}
.container input {
  width: 100%;
  height: 52px;
  background: #eeeeee;
  border-radius: 8px;
  border: none;
  padding: 13px;
}
