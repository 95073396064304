.content {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}
.content h1 {
  font-family: var(--secondary-font);
  color: #cf0;
  font-size: 3.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 3.25rem;
}
.content h2 {
  color: #fff;
  font-family: var(--secondary-font);
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
}
.genrateLink {
  padding: 16px 24px;
  background: #cf0;
  filter: drop-shadow(rgba(204, 255, 0, 0.6) 4px 4px 0px);
  color: #000;
  font-family: var(--primary-font);
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  text-transform: uppercase;
}
.genrateLink:disabled {
  opacity: 50%;
}
/* talewind md device */
@media (max-width: 768px) {
  .content {
    width: 100%;
    justify-content: space-between;
  }
}
